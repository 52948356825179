@import "../../common.scss";
@import "../elements/FullScreenBtn.scss";

.hp-layoutV2 {
	.ms-homepage-background {
		width: 100%;
		height: 100%;
		display: flex;
    justify-content: center;
    align-items: center;
		background-image: url("../../assets/v2/ms-background.svg");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.ms-bd {
		width: responsiveSizeToV2(1152);
		&__books {
			display: flex;
			flex-wrap: wrap;
			height: 100%;
			max-height: responsiveSizeLegacyToV2(980);
			overflow-y: scroll;
			justify-content: flex-start;
			&::-webkit-scrollbar {
				width: 0;
			}
		}
		&__item-container {
			text-decoration: none;
			position: relative;
			width: responsiveSizeLegacyToV2(258);
			height: responsiveSizeLegacyToV2(282);
			margin-right: responsiveSizeLegacyToV2(20);
			margin-left: responsiveSizeLegacyToV2(20);
			margin-bottom: responsiveSizeLegacyToV2(15);
			&__thumbnails {
				width: responsiveSizeLegacyToV2(258);
				height: responsiveSizeLegacyToV2(258);
				cursor: pointer;
				border-radius: responsiveSizeLegacyToV2(5);
			}
			&__title {
				font-family: "Avenir";
				font-weight: bolder;
				font-size: responsiveSizeLegacyToV2(15);
				margin: 0;
				color: white;
				text-align: center;
			}
			.overlay {display: none;}
			&.ms-bd__item-blocked {
				.overlay {
					display: block;
					position: absolute;
					z-index: 2;
					top: 0;
					left: 0;
					width: responsiveSizeLegacyToV2(258);
					height: responsiveSizeLegacyToV2(258);
					border-radius: responsiveSizeLegacyToV2(5);
					background-color: rgba($color: #51AABC, $alpha: 0.7);
					svg {
						position: absolute;
						width: responsiveSizeV2(33);
						height: responsiveSizeV2(33);
						right: responsiveSizeLegacyToV2(7);
						bottom: responsiveSizeLegacyToV2(7);
					}
				}
			}
		}
	}
}

.portrait {
	.ms-bd {
		left: initial;
		&__books {
			max-height: responsiveSizeLegacyToV2(1480);
			justify-content: center;
		}
		&__item-container {
			width: responsiveSizeLegacyToV2(440);
			height: responsiveSizeLegacyToV2(440);
			margin-right: responsiveSizeLegacyToV2(20);
			margin-left: responsiveSizeLegacyToV2(20);
			margin-bottom: responsiveSizeLegacyToV2(60);
			&__thumbnails {
				width: 100%;
				height: 100%;
			}
			&__title {
				display: none;
			}
		}
	}
}
