@import "../../common.scss";

.ms-home-freemium-available {
	top: 0;
	z-index: 2;
	width: 100%;
	color: white;
	position: absolute;
	text-align: center;
	font-family: 'Bilo';
	background-color: #51AABC;
	top: responsiveSizeV2(26);
	width: responsiveSizeV2(338);
	height: responsiveSizeV2(143);
	border-radius: responsiveSizeV2(14);
	.ms-home-freemium-available-title {
		font-weight: 600;
		font-size: responsiveSizeV2(22);
		margin-top: responsiveSizeV2(10);
	}
	.ms-home-freemium-available-text {
		font-family: 'Inter';
		font-weight: bold;
		font-size: responsiveSizeV2(25);
		margin-top: responsiveSizeV2(4);
		margin-bottom: responsiveSizeV2(8);
	}
}

.hp-freemium-banner-sub-btn {
	z-index: 2;
	&, svg {
		cursor: pointer;
		width: responsiveSizeV2(209);
		height: responsiveSizeV2(45);
	}
}