@import "../../common.scss";

.main-slider {
	overflow: hidden;
	width: responsiveSizeLegacyToV2(790);
	height: responsiveSizeLegacyToV2(790);
	&__swiper {
		display: flex;
		overflow-x: visible;
		transition-property: transform;
		will-change: transform;
	}
	&__arrow {
		color: #4b4b4b;
		width: responsiveSizeLegacyToV2(86);
		height: responsiveSizeLegacyToV2(86);
		cursor: pointer;
		&--prev {
			left: responsiveSizeLegacyToV2(-157);
		}
		&--next {
			right: responsiveSizeLegacyToV2(-157);
		}
	}
	&__reduce-screen {
		display: none;
		cursor: pointer;
	}
}

.content-slide {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
}

.portrait {
	.main-slider {
		width: 100%;
		height: 100%;
		width: responsiveSizeLegacyToV2(1040);
		height: responsiveSizeLegacyToV2(1040);
	}
}

.fullscreen {
	.main-slider {
		width: responsiveSizeLegacyToV2(1182);
		height: responsiveSizeLegacyToV2(1182);
		margin: auto;
		&__arrow {
			&--prev {
				left: 0;
			}
			&--next {
				right: 0;
			}
		}
		&__reduce-screen {
			display: block;
			position: absolute;
			bottom: responsiveSizeLegacyToV2(30);
			width: responsiveSizeLegacyToV2(104.87);
			height: responsiveSizeLegacyToV2(104.87);
			left: responsiveSizeLegacyToV2(290);
			z-index: 4;
		}
	}
}
