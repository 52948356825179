@import "../../../../common.scss";

.hideDiv {
	display: none !important;
}

.loader {
	position: absolute; 
	z-index: 9; 
	height: 100%; 
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.hp-blockedModal-popup {
	z-index: 10;
  display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
	background-color: rgba($color: #51AABC, $alpha: 0.9);
	&__wrapper {
		display: flex;
		position: relative;
		flex-direction: column;
		align-items: center;
		background-color: white;
		width: responsiveSizeToV2(1020);
		height: responsiveSizeToV2(820);
		border-radius: responsiveSizeToV2(42);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
		.unblock-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			margin: 0;
			width: responsiveSizeToV2(150);
		}
	}
	iframe {
		background-color: none;
	}
	&__close {
		position: absolute;
		top: responsiveSizeToV2(10);
		right: responsiveSizeToV2(10);
		width: responsiveSizeToV2(80);
		height: responsiveSizeToV2(80);
	}
	&__header {
		display: flex;
		align-items: center;
		font-family: 'Avenir';
		font-size: responsiveSizeToV2(23);
		margin-top: responsiveSizeToV2(25);
		margin-bottom: responsiveSizeToV2(22);
		&-icon {
			&, svg {
				width: responsiveSizeToV2(120);
				height: responsiveSizeToV2(120);
			}
		}
		&-text {
			margin-left: responsiveSizeToV2(21);
		}
		&-desc {
			font-size: responsiveSizeToV2(19);
		}
	}
	.hp-proposition-condition {
		a {
			color: #51AABC;
			font-weight: bold;
		}
	}
	&__unblock-text {
		color: #51AABC;
		font-family: 'Fredoka One';
		font-size: responsiveSizeToV2(34);
	}
	&__propositions {
		display: flex;
		position: relative;
		width: responsiveSizeToV2(860); 
		justify-content: center;
		margin-top: responsiveSizeToV2(32);
		> div { 
			color: #808080;
			font-family: 'Avenir';
			font-size: responsiveSizeToV2(19);
			width: responsiveSizeToV2(260); 
			&:not(:last-child) {
				margin-right: responsiveSizeToV2(40); 
			}
			line-height: 1.2;
			.hp-proposition-loader {
				display: flex;
				align-items: center;
				justify-content: center;
				width: responsiveSizeToV2(260); 
				height: responsiveSizeToV2(300); 
			}
			.hp-proposition-icon-share-disabled {
				position: relative;
				width: responsiveSizeToV2(260); 
				height: responsiveSizeToV2(300);
				span {
					top: 0;
					left: 0;
					position: absolute;
				}
			}
			.hp-proposition-icon {
				svg {
					width: responsiveSizeToV2(260); 
					height: responsiveSizeToV2(300); 
				}
			}
			.hp-proposition-icon-text {
				display: flex;
				justify-content: center;
				position: relative;
				span {
					color: white;
					position: absolute;
					text-align: center;
					font-family: 'Fredoka One';
					top: responsiveSizeToV2(170);
					width: responsiveSizeToV2(200);
					font-size: responsiveSizeToV2(19);
				}
			}
			.hp-proposition-rule {
				font-weight: 900;
				margin-top: responsiveSizeToV2(8);
			}
		}
	}

	&.extra-step {
		background-color: rgba($color: #1C1D1E, $alpha: 0.9);
		.hp-blockedModal-popup__wrapper {
			background-color: transparent;
			height: responsiveSizeToV2(787);
			width: responsiveSizeToV2(1084);
			align-items: flex-start;
			justify-content: flex-end;
			.hp-blockedModal-popup__close {
				top: 0;
				right: 0;
				height: responsiveSizeToV2(64);
				width: responsiveSizeToV2(64);
			}
		}
		
		.iframe-steps {
			background-color: white;
			height: responsiveSizeToV2(741);
			width: responsiveSizeToV2(1020);
			border-radius: responsiveSizeToV2(42);
		}
	}
}