@import "../../common.scss";

.bd-left-area {
	display: flex;
	justify-content: center;
	height: 100%;
	position: relative;
	z-index: 0;
	&__info {
		position: absolute;
		left: 50%;
		transform: translate(-50%);
		bottom: responsiveSizeLegacyToV2(516);
		width: responsiveSizeLegacyToV2(258);
		height: responsiveSizeLegacyToV2(292);
		&--thumbnails {
			width: responsiveSizeLegacyToV2(258);
			height: responsiveSizeLegacyToV2(258);
			border-radius: responsiveSizeLegacyToV2(5);
		}
		&--title {
			margin: 0;
			font-family: "Avenir";
			font-weight: bolder;
			color: white;
			font-size: responsiveSizeLegacyToV2(22);
			text-align: center;
		}
	}
	&__share {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: absolute;
		bottom: responsiveSizeLegacyToV2(342);
		font-family: "Avenir";
		font-size: responsiveSizeLegacyToV2(20);
		font-weight: bolder;
		text-transform: uppercase;
		color: #c3c3c3;
		cursor: pointer;
		&--btn-icon {
			color: #51aabc;
			width: responsiveSizeLegacyToV2(64.07);
			height: responsiveSizeLegacyToV2(64.07);
			cursor: pointer;
		}
		&--btn-text {
			margin: 0;
			margin-top: responsiveSizeLegacyToV2(5);
		}
	}
	&__buttons {
		position: absolute;
		bottom: responsiveSizeLegacyToV2(93);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: responsiveSizeLegacyToV2(281);
		height: responsiveSizeLegacyToV2(152);
		color: white;
		font-family: "Fredoka One";
		font-size: responsiveSizeLegacyToV2(24);
		text-transform: uppercase;
		&__full-screen,
		&__home {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			border-width: responsiveSizeLegacyToV2(3);
			border: solid white;
			border-radius: responsiveSizeLegacyToV2(42);
			width: 100%;
			height: responsiveSizeLegacyToV2(62);
			color: white;
			text-decoration: none;
			text-align: center;
		}
		&__full-screen {
			svg {
				width: responsiveSizeLegacyToV2(36.48);
				height: responsiveSizeLegacyToV2(36.48);
				margin-right: responsiveSizeLegacyToV2(20);
				cursor: pointer;
			}
		}
		&__home {
			svg {
				cursor: pointer;
				width: responsiveSizeLegacyToV2(31.9);
				height: responsiveSizeLegacyToV2(31.9);
				margin-right: responsiveSizeLegacyToV2(20);
			}
		}
	}
}
