@import "../../common.scss";

.not-fullscreen {
	position: relative;
	height: 100%;
	z-index: 2;
	.hidden-arrow {
		display: none;
	}
}

.ms-homepage-v3-background {
	width: 100%;
	height: 100%;
	background: rgb(54, 54, 54);
	display: flex;
	justify-content: center;
	align-items: center;
}

.ms-homepage-v3 {
	height: var(--mainContentHeight);
	width: var(--vw);
	position: relative;
	.ms-homepage-v3__imageTitle {
		color: white;
		position: absolute;
		width: 100%;
		text-align: center;
		top: responsiveSizeLegacyToV2(-40);
		font-size: responsiveSizeLegacyToV2(30);
	}
	&__container-content {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		.main-slider {
			margin: 0 responsiveSizeLegacyToV2(71);
		}
	}

	&__image {
		width: responsiveSizeLegacyToV2(790);
		height: responsiveSizeLegacyToV2(790);
		border-radius: responsiveSizeLegacyToV2(10);
		&--hidden {
			display: none;
		}
	}
	.loader-bd {
		position: absolute;
	}
	.loader-bd:not(:first-child) {
		display: none;
		&.force-display {
			display: flex;
		}
	}


	&__bottom {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		top: responsiveSizeLegacyToV2(970);
		width: responsiveSizeLegacyToV2(790);
		&__dots {
			display: flex;
		}
		&__dot {
			width: responsiveSizeLegacyToV2(22);
			height: responsiveSizeLegacyToV2(22);
			background-color: white;
			opacity: 0.2;
			border-radius: 100%;
		}
		&__dot:not(:last-child) {
			margin-right: responsiveSizeLegacyToV2(17);
		}
	}
	.ms-homepage-v3__container-content {
		position: relative;
		justify-content: flex-start;
		align-items: flex-start;
		width: responsiveSizeLegacyToV2(932);
		height: responsiveSizeLegacyToV2(900);
		margin-top: responsiveSizeLegacyToV2(130);
		margin-left: responsiveSizeLegacyToV2(115);
		.main-slider__arrow--next,
		.main-slider__arrow--prev {
			z-index: 10;
			position: absolute;
			left: inherit;
			right: responsiveSizeLegacyToV2(183);
			bottom: responsiveSizeLegacyToV2(0);
			width: responsiveSizeLegacyToV2(90);
			height: responsiveSizeLegacyToV2(90);
		}

		.main-slider__arrow--next {
			right: responsiveSizeLegacyToV2(72);
		}
		.ms-homepage-v3__bottom {
			top: inherit;
			bottom: responsiveSizeLegacyToV2(60);
			width: responsiveSizeLegacyToV2(932);
		}
	}

	.main-slider__full-screen {
		cursor: pointer;
		position: absolute;
		left: responsiveSizeLegacyToV2(71);
		bottom: responsiveSizeLegacyToV2(0);
		width: responsiveSizeLegacyToV2(90);
		height: responsiveSizeLegacyToV2(90);
	}

	.ms-allBd {
		display: flex;
		position: absolute;
		flex-direction: column;
		justify-content: space-between;
		top: responsiveSizeLegacyToV2(28.4);
		right: responsiveSizeLegacyToV2(70);
		width: responsiveSizeLegacyToV2(300);
		height: responsiveSizeLegacyToV2(1115);
		overflow-y: scroll;
	
		&::-webkit-scrollbar {
			width: 0;
		}
		.loader-bd {
			display: none;
		}
		a {
			color: white;
			font-family: "Avenir";
			align-items: center;
			text-align: center;
			font-size: responsiveSizeLegacyToV2(22);
			margin-bottom: responsiveSizeLegacyToV2(12);
			opacity: 1;
			position: relative;
			.overlay { display: none; }
			&.ms-bd__item-blocked {
				.overlay {
					display: flex;
					position: absolute;
					z-index: 2;
					top: 0;
					left: 0;
					width: responsiveSizeLegacyToV2(300);
					height: responsiveSizeLegacyToV2(300);
					border-radius: responsiveSizeLegacyToV2(10);
					background-color: rgba($color: #4D5B5C, $alpha: 0.8);
					justify-content: center;
					align-items: center;
					svg {
						position: absolute;
						width: responsiveSizeV2(27);
						height: responsiveSizeV2(31);
					}
				}
			}
		}
		&__bd-list {
			width: responsiveSizeLegacyToV2(300);
			height: responsiveSizeLegacyToV2(300);
			border-radius: responsiveSizeLegacyToV2(10);
		}
		&--hidden {
			display: none;
		}
		a {
			text-decoration: none;
		}
		&__home-return {
			display: none !important;
		}
	}
}

.dot-active {
	opacity: 1;
	transform: scale(1);
}



// ********* Fullscreen mode ***********

.fullscreen {
	z-index: 999;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 101%;
	height: 100%;
	background-color: black;
	.ms-allBd,
	.hp-maincontent-left-border {
		display: none;
	}
	.ms-homepage-v3 {
		.ms-homepage-v3__container-content {
			.main-slider__arrow--next {
				bottom: 50%;
				right: 2%;
			}
			.main-slider__arrow--prev {
				bottom: 50%;
				left: 2%;
				right: inherit;
			}
		}
		.main-slider__reduce-screen {
			left: responsiveSizeLegacyToV2(36);
		}
	}
	.main-slider__full-screen {
		display: none;
	}
	.ms-bd-background {
		background: initial;
	}
	.ks-content {
		margin: initial;
		width: 100%;
		height: 100%;
	}
	.ms-homepage-v3 {
		&, &__image {
			height: responsiveSizeLegacyToV2(1182);
		}
		&__image {
			width: responsiveSizeLegacyToV2(1182);
		}
		&__container-content {
			position: relative;
			display: flex;
			top: initial;
			left: initial;
			transform: initial;
			flex-direction: row;
			height: 100%;
			margin: auto;
			width: 100%;
			justify-content: center;
		}
		&__bottom {
			display: none;
		}
	}
	.ms-allBd {
		height: responsiveSizeLegacyToV2(1088);

		&__home-return {
			display: none;
		}
	}
}
