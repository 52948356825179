@import "../../common.scss";

.ms-home-icon {
	width: 100%;
	display: flex;
	justify-content: center;
	flex: 1;
	height: responsiveSizeLegacyToV2(146.11);
	svg {
		height: 100%;
		width: responsiveSizeLegacyToV2(325.53);
	}
}
