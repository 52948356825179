@import "../../common.scss";

.not-fullscreen {
	position: relative;
	height: 100%;
	z-index: 2;
	.hidden-arrow {
		display: none;
	}
}

.ms-bd-background {
	width: 100%;
	height: 100%;
	background: rgb(54, 54, 54);
	display: flex;
	justify-content: center;
	align-items: center;
}

.ms-bdImages {
	height: var(--mainContentHeight);
	width: var(--vw);
	position: relative;
	&__container-content {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		.main-slider {
			margin: 0 responsiveSizeLegacyToV2(71);
		}
	}

	&__image {
		width: responsiveSizeLegacyToV2(790);
		height: responsiveSizeLegacyToV2(790);
		border-radius: responsiveSizeLegacyToV2(5);
		&--hidden {
			display: none;
		}
	}
	.loader-bd {
		position: absolute;
	}
	.loader-bd:not(:first-child) {
		display: none;
	}

	&__bottom {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		top: responsiveSizeLegacyToV2(970);
		width: responsiveSizeLegacyToV2(790);
		@include responsive-size(margin-top, 23);
		&__dots {
			display: flex;
		}
		&__dot {
			width: responsiveSizeLegacyToV2(19);
			height: responsiveSizeLegacyToV2(19);
			background-color: white;
			opacity: 0.2;
			transform: scale(0.7);
			border-radius: 100%;
		}
		&__dot:not(:last-child) {
			margin-right: responsiveSizeLegacyToV2(17);
		}
	}
}

.dot-active {
	opacity: 1;
	transform: scale(1);
}

.ms-allBd {
	display: flex;
	position: absolute;
	flex-direction: column;
	justify-content: space-between;
	top: responsiveSizeLegacyToV2(28.4);
	right: responsiveSizeLegacyToV2(70);
	width: responsiveSizeLegacyToV2(210);
	height: responsiveSizeLegacyToV2(1115);
	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 0;
	}
	.loader-bd {
		display: none;
	}
	a {
		color: white;
		font-family: "Avenir";
		font-weight: bolder;
		align-items: center;
		font-size: responsiveSizeLegacyToV2(12);
		margin-bottom: responsiveSizeLegacyToV2(12);
		opacity: 0.5;
		&:hover {
			opacity: 1;
		}
	}
	&__bd-list {
		width: responsiveSizeLegacyToV2(210);
	}
	&--hidden {
		display: none;
	}
	a {
		text-decoration: none;
	}
	&__home-return {
		display: none !important;
	}
}

// ********* Fullscreen mode ***********

.fullscreen {
	z-index: 999;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 101%;
	height: 100%;
	background-color: black;
	.ms-bd-background {
		background: initial;
	}
	.ks-content {
		margin: initial;
		width: 100%;
		height: 100%;
	}
	.ms-bdImages {
		&, &__image {
			height: responsiveSizeLegacyToV2(1182);
		}
		&__image {
			width: responsiveSizeLegacyToV2(1182);
		}
		&__container-content {
			position: relative;
			display: flex;
			top: initial;
			left: initial;
			transform: initial;
			flex-direction: row;
			height: 100%;
			margin: auto;
			width: 75vw;
			justify-content: center;
		}
		&__bottom {
			display: none;
		}
	}
	.ms-allBd {
		height: responsiveSizeLegacyToV2(1088);

		&__home-return {
			display: none;
		}
	}
}

// ****** Portrait mode ******
.portrait {
	.ms-bdImages {
		&__container-content {
			top: responsiveSizeLegacyToV2(180);
			
		}
		&__image {
			width: initial;
			height: initial;
			width: responsiveSizeLegacyToV2(1040);
			height: responsiveSizeLegacyToV2(1040);
		}
		&__bottom {
			width: responsiveSizeLegacyToV2(239);
			margin-top: responsiveSizeLegacyToV2(34);
			&__dot {
				width: responsiveSizeLegacyToV2(28);
				height: responsiveSizeLegacyToV2(28);
				transform: scale(0.9);
			}
		}
	}
	.ms-allBd {
		flex-direction: row;
		opacity: 1;
		width: 100%;
		height: initial;
		top: initial;
		right: 0;
		bottom: 0;
		a {
			margin: initial;
			margin-right: responsiveSizeLegacyToV2(40);
			span {
				display: none;
			}
		}
		&__bd-list {
			width: responsiveSizeLegacyToV2(240);
			height: responsiveSizeLegacyToV2(240);
		}
		&__home-return {
			min-width: responsiveSizeLegacyToV2(240);
			min-height: responsiveSizeLegacyToV2(240);
			text-transform: uppercase;
			font-family: "Fredoka One";
			font-size: responsiveSizeLegacyToV2(40);
			color: white;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			svg {
				width: responsiveSizeLegacyToV2(83);
				height: responsiveSizeLegacyToV2(83);
				margin-bottom: responsiveSizeLegacyToV2(20.6);
			}
			p {
				margin: 0;
			}
		}
	}
}
