@import "../../common.scss";

.ms-iconFullScreen {
	position: absolute;
	left: 0;
}

.ms-fullscreen-icon {
	color: white;
	@include responsive-size(width, 40.27);
	@include responsive-size(height, 40.27);
	cursor: pointer;
}
.standalone {
	z-index: 999;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 101%;
	height: 100%;
	background-color: black;
	.ms-bdImages {
		&__container-content {
			display: block;
			@include responsive-size(width, 1145);
			height: 100%;
			margin: auto;
			justify-content: initial;
		}
		&__imageWrapper {
			@include responsive-size(height, 1145);
			@include responsive-size(width, 1145);
		}
		&__image {
			height: inherit !important ;
			width: inherit !important ;
		}
		&__download-icon {
			display: none;
		}
		&__bottom {
			width: 100% !important;
		}

		&__content__name,
		&__bottom__dots,
		.ms-download-icon,
		.ms-allBd {
			display: none;
		}
		&__bottom {
			margin: 0;
		}
	}
	.main-slider {
		height: 100% !important;
		width: 100% !important;
		&__arrow--prev {
			left: 9.5%;
		}
		&__arrow--next {
			right: 9.5%;
		}
	}

	.ms-iconFullScreen {
		visibility: visible;
		@include responsive-size(left, -60);
		@include responsive-size(bottom, 30);
	}
}
